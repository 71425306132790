import {Box, Divider, Stack, useTheme} from '@mui/material';
import React, {FC} from 'react';
import {
    Location
} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/parameters/partials/location/Location';
import {
    Title
} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/parameters/partials/title/Title';
import {
    OfferCount
} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/parameters/partials/offerCount/OfferCount';
import {
    Price
} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/parameters/partials/price/Price';
import {
    AdvertisementsInfo
} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/parameters/partials/advertisementsInfo/AdvertisementsInfo';
import {
    ECategoriesLabel
} from 'modules/theme/components/listing/cards/devProjectCard/enums/category/ECategoriesLabel';

interface IParameters {
    price: string;
    location: string;
    unitPrice?: string;
    projectName: string;
    categories: ECategoriesLabel[];
    totalAdvertisementsCount: number;
    advertisementsCountMatchesTheFilter?: number;
}

export const Parameters: FC<IParameters> = ({
    price,
    location,
    categories,
    unitPrice,
    projectName,
    totalAdvertisementsCount,
    advertisementsCountMatchesTheFilter,
}) => {
    const {palette} = useTheme();

    return (
        <Stack direction="column" gap={.5} color={palette.labelPrimaryInverse.main}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-end'}
            >
                <Box flex={'1 1 80%'} overflow={'hidden'}>
                    <Title projectName={projectName}/>
                </Box>
                {advertisementsCountMatchesTheFilter >= 0 &&
                    <Box flex={'0 1 20%'}>
                        <OfferCount count={advertisementsCountMatchesTheFilter}/>
                    </Box>
                }
            </Stack>
            <Divider color="dividerTransparent" sx={{
                borderColor: 'dividerTransparent',
                backgroundColor: 'transparent',
                my: {xs: .5, md: 1}
            }}/>
            <Stack
                gap={.5}
                direction={{xs: 'column', md: 'row'}}
                justifyContent={{md: 'space-between'}}
                alignItems={{md: 'center'}}
            >
                <Stack direction={'column'} gap={.5}>
                    <Location location={location}/>
                    <AdvertisementsInfo categories={categories} totalAdvertisementsCount={totalAdvertisementsCount}/>
                </Stack>
                <Price price={price} unitPrice={unitPrice}/>
            </Stack>
        </Stack>
    );
};
