import { useCallback } from 'react';
import useLoading from 'modules/state/app/hook/useLoading';
import { postSearchAgent } from 'components/searchAgent/api/searchAgent/postSearchAgent';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';
import { getSearchAgentRequestoDto } from 'components/searchAgent/helpers/api/mapper/getSearchAgentRequestoDto';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {
    getSubscribeAlertEvent
} from 'modules/gtmEvents/helpers/events/advertisement/listing/partials/advertisementListDataHelper';
import {
    validateSearchAgentRequestDto
} from 'components/searchAgent/helpers/api/validation/validateSearchAgentRequestDto';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';

interface IReturn {
    isLoading: boolean;
    onSend: (data: ISearchAgentData) => Promise<boolean>;
}

export const usePostSearchAgent = (
    filterDataFromUrl: FilterDataFromUrlType,
): IReturn => {
    const { start: startLoading, stop: stopLoading, isLoading } = useLoading();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    const onSend = useCallback(
        async (formData: ISearchAgentData): Promise<boolean> => {
            const requestoDtoFilter = getSearchAgentRequestoDto(
                formData,
                filterDataFromUrl,
            );

            const isValid = validateSearchAgentRequestDto(
                requestoDtoFilter,
            );

            if (!isValid) {
                return;
            }

            try {
                startLoading();
                await postSearchAgent(requestoDtoFilter);
                stopLoading();
                gtmSendEvent(
                    getSubscribeAlertEvent(formData, true)
                );
                return true;
            } catch (e) {
                gtmSendEvent(
                    getSubscribeAlertEvent(formData, false)
                );
                return false;
            } finally {
                stopLoading();
            }
        },
        [startLoading, stopLoading, filterDataFromUrl, gtmSendEvent]
    );

    return {
        isLoading,
        onSend,
    };
};
