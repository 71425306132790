import {
    AdvertisementSearchAgentSaveSearchAgentDto,
} from 'modules/api/generated';

export const validateSearchAgentRequestDto = (
    dto: AdvertisementSearchAgentSaveSearchAgentDto
): boolean => {
    const filterData = dto.filterData;

    return !!filterData.transaction ||
        !!filterData.categories?.length ||
        !!filterData?.locations?.length ||
        !!filterData.onlyNewbuilding ||
        !!filterData.onlyWithVideo ||
        !!filterData.priceFrom ||
        !!filterData.priceTo ||
        !!filterData.areaFrom ||
        !!filterData.areaTo ||
        !!filterData.fulltext;
};
