import React, { FC } from 'react';
import { Box } from '@mui/material';
import { CloseButton } from 'components/page/advertisement/common/closeButton/CloseButton';
import { Content } from 'components/searchAgent/components/saveSearchModal/partials/content/Content';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';
interface ISaveSeachModal {
    email: string;
    isLoading: boolean;
    onClose: () => void;
    searchName?: string;
    onSend: (data: ISearchAgentData) => Promise<boolean>;
}

export const SaveSearchModal: FC<ISaveSeachModal> = ({ onClose, isLoading, onSend, email, searchName }) => {
    return (
        <Box maxWidth={{md: 400}}>
            <Box mb={2}>
                <CloseButton onClick={onClose} />
            </Box>
            <Box>
                <Content
                    onClose={onClose}
                    email={email}
                    onSend={onSend}
                    isLoading={isLoading}
                    searchName={searchName}
                />
            </Box>
        </Box>
    );
};