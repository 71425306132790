import { FC } from 'react';
import { Stack } from '@mui/material';
import { Labels } from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/badges/partials/labels/Labels';
import { PackageBadges } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/top/partials/PackageBadges';

interface IBadges {
    isTop?: boolean;
    isPremium?: boolean;
    isProfi?: boolean;
}

export const Badges: FC<IBadges> = ({ isTop, isPremium, isProfi }) => {
    return (
        <Stack
            direction="row"
            alignItems="start"
            justifyContent="space-between"
        >
            <PackageBadges isTop={isTop} isPremium={isPremium} />
            <Labels isDevProject isProfi={isProfi} />
        </Stack>
    );
};
