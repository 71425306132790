import {FC} from 'react';
import {Box} from '@mui/material';
import {IDevProject} from 'modules/theme/components/listing/cards/devProjectCard/interfaces/IDevProject';
import {Photo} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/photo/Photo';
import {Wrapper} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/wrapper/Wrapper';
import {Content} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/Content';

interface IDevProjectCard {
    devProject: IDevProject;
    devProjectDetailUrl: string;
    onClick?: () => void;
}

export const DevProjectCard: FC<IDevProjectCard> = ({
    onClick,
    devProject,
    devProjectDetailUrl,
}) => {
    const {
        imageUrl,
        location,
        projectName,
        price: {price, unitPrice},
        packages: {isTop, isPremium},
        labels: {isProfi},
        advertisementsInfo: {
            advertisementsCategories,
            totalAdvertisementsCount,
            advertisementsCountMatchesTheFilter,
        },
    } = devProject;

    return (
        <Wrapper href={devProjectDetailUrl} onClick={onClick}>
            <Box position="relative" height="100%">
                <Content
                    isTop={isTop}
                    price={price}
                    isProfi={isProfi}
                    location={location}
                    isPremium={isPremium}
                    unitPrice={unitPrice}
                    projectName={projectName}
                    advertisementsCategories={advertisementsCategories}
                    totalAdvertisementsCount={totalAdvertisementsCount}
                    advertisementsCountMatchesTheFilter={advertisementsCountMatchesTheFilter}
                />
                <Photo photoUrl={imageUrl}/>
            </Box>
        </Wrapper>
    );
};
