import { FC, ReactNode } from 'react';
import Link from 'components/link/Link';
import { Box, Theme, useMediaQuery } from '@mui/material';

interface IWrapper {
    href: string;
    onClick?: () => void;
    children: ReactNode | ReactNode[];
}

export const Wrapper: FC<IWrapper> = ({ children, href, onClick }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <Link
        noUnderline
        openInNewTab={!isMobile}
        href={href}
        onClick={onClick}
    >
        <Box
            borderRadius={2}
            overflow={'hidden'}
            height={300}
        >
            {children}
        </Box>
    </Link>;
};
