import { FC } from 'react';
import Image from 'components/image/Image';
import Icon from 'modules/theme/components/assets/badges/svg/ProfiClient.svg?url';

interface IProfiClient {
    width: number | string;
}

export const ProfiClient: FC<IProfiClient> = ({ width }) => {
    const w = parseInt(`${width}`);

    return <Image alt="" width={w} height={w} src={Icon} />;
};
