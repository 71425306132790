import {FC} from 'react';
import {
    Wrapper
} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/wrapper/Wrapper';
import {
    Badges
} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/badges/Badges';
import {
    Parameters
} from 'modules/theme/components/listing/cards/devProjectCard/components/card/partials/content/partials/parameters/Parameters';
import {ECategoriesLabel} from 'modules/theme/components/listing/cards/devProjectCard/enums/category/ECategoriesLabel';

interface IContent {
    price: string;
    isTop?: boolean;
    location: string;
    isProfi?: boolean;
    unitPrice?: string;
    projectName: string;
    isPremium?: boolean;
    totalAdvertisementsCount?: number;
    advertisementsCategories: ECategoriesLabel[];
    advertisementsCountMatchesTheFilter?: number;
}

export const Content: FC<IContent> = ({
    isTop,
    price,
    isProfi,
    location,
    unitPrice,
    isPremium,
    projectName,
    totalAdvertisementsCount,
    advertisementsCategories,
    advertisementsCountMatchesTheFilter,
}) => {
    return (
        <Wrapper>
            <Badges isPremium={isPremium} isTop={isTop} isProfi={isProfi} />
            <Parameters
                price={price}
                location={location}
                unitPrice={unitPrice}
                projectName={projectName}
                categories={advertisementsCategories}
                totalAdvertisementsCount={totalAdvertisementsCount}
                advertisementsCountMatchesTheFilter={
                    advertisementsCountMatchesTheFilter
                }
            />
        </Wrapper>
    );
};
