import {
    AdvertisementSearchAgentSaveSearchAgentDto,
    AdvertisementsCountFilterParametersDtoCategoriesEnum,
    AdvertisementsCountFilterParametersDtoTransactionEnum,
} from 'modules/api/generated';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';
import { CategoryEnumRecord } from 'components/searchAgent/constants/record/api/category/CategoryEnumRecord';
import { FrequencyEnumRecord } from 'components/searchAgent/constants/record/api/frequency/FrequencyEnumRecord';
import { TransactionEnumRecord } from 'components/searchAgent/constants/record/api/transaction/TransactionEnumRecord';
import { FilterDataFromUrlType } from 'modules/advertisementsFilter/types/FilterDataFromUrlType';

export const getSearchAgentRequestoDto = (
    formData: ISearchAgentData,
    filterDataFromUrl: FilterDataFromUrlType
): AdvertisementSearchAgentSaveSearchAgentDto => {
    const {
        areaTo,
        priceTo,
        fulltext,
        areaFrom,
        priceFrom,
        categories,
        locations,
        transaction,
        isOnlyNewBuilding,
    } = filterDataFromUrl;

    const { email, searchName: searchAgentname, frequency } = formData;

    let requestTransaction: AdvertisementsCountFilterParametersDtoTransactionEnum;
    let requestCategories: AdvertisementsCountFilterParametersDtoCategoriesEnum[] =
        [];

    const locationIds = locations.length
        ? locations.map((location) => location.id)
        : [];

    if (transaction) {
        requestTransaction = TransactionEnumRecord[transaction.name];
    }

    if (categories) {
        requestCategories = categories.map((cat) => CategoryEnumRecord[cat.name]);
    }

    return {
        email,
        searchAgentname,
        frequency: FrequencyEnumRecord[frequency],
        filterData: {
            areaTo,
            priceTo,
            areaFrom,
            fulltext,
            priceFrom,
            locations: locationIds,
            categories: requestCategories,
            transaction: requestTransaction,
            onlyNewbuilding: isOnlyNewBuilding,
        },
    };
};
