import { SearchAgent } from 'modules/api/clients/searchAgent/SearchAgent';
import { AdvertisementSearchAgentSaveSearchAgentDto } from 'modules/api/generated';

export const postSearchAgent = async (
    filter: AdvertisementSearchAgentSaveSearchAgentDto
): Promise<void> => {
    try {
        return await new SearchAgent().createSearchAgent(filter);
    } catch (error) {
        throw new Error(
            `Failed to fetch filter data to sef format. Error: ${error.message}`
        );
    }
};
