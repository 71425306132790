import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { AdvertisementsCountFilterParametersDtoCategoriesEnum } from 'modules/api/generated';

export const CategoryEnumRecord: Record<
    EMainCategory | ESubCategory,
    AdvertisementsCountFilterParametersDtoCategoriesEnum
> = {
    [EMainCategory.REAL_ESTATES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.RealEstates,
    [EMainCategory.APARTMENTS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Apartments,
    [EMainCategory.HOUSES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Houses,
    [EMainCategory.SECONDARY_HOUSES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Houses,
    [EMainCategory.COTTAGES_AND_CABINS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.CottagesAndCabins,
    [EMainCategory.LANDS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Lands,
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryRecreationalProperties,
    [EMainCategory.SECONDARY_LANDS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Lands,
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondarySpacesAndObjects,
    [EMainCategory.SPACES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Spaces,
    [EMainCategory.OBJECTS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Objects,
    [ESubCategory.STUDIO_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.StudioApartment,
    [ESubCategory.DOUBLE_STUDIO_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.DoubleStudioApartment,
    [ESubCategory.ONE_ROOM_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OneRoomApartment,
    [ESubCategory.TWO_ROOM_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.TwoRoomApartment,
    [ESubCategory.THREE_ROOM_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.ThreeRoomApartment,
    [ESubCategory.FOUR_ROOM_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.FourRoomApartment,
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.FivePlusRoomApartment,
    [ESubCategory.HOLIDAY_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.HolidayApartment,
    [ESubCategory.LOFT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Loft,
    [ESubCategory.MAISONETTE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Maisonette,
    [ESubCategory.OTHER_TYPE_OF_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OtherTypeOfApartment,
    [ESubCategory.FAMILY_HOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.FamilyHouse,
    [ESubCategory.COUNTRY_HOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.CountryHouse,
    [ESubCategory.FARM_SETTLEMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.FarmSettlement,
    [ESubCategory.MOBILE_HOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.MobileHouse,
    [ESubCategory.HOUSEBOAT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Houseboat,
    [ESubCategory.OTHER_OBJECT_FOR_HOUSING]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OtherObjectForHousing,
    [ESubCategory.CABIN_AND_LOG_CABIN]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.CabinAndLogCabin,
    [ESubCategory.COTTAGE_AND_RECREATION_HOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.CottageAndRecreationHouse,
    [ESubCategory.GARDEN_HUT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.GardenHut,
    [ESubCategory.OTHER_RECREATIONAL_OBJECT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OtherRecreationalObject,
    [ESubCategory.LAND_FOR_FAMILY_HOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.LandForFamilyHouse,
    [ESubCategory.RECREATIONAL_LAND]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.RecreationalLand,
    [ESubCategory.LAND_FOR_HOUSING_CONSTRUCTION]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.LandForHousingConstruction,
    [ESubCategory.LAND_FOR_CIVIC_AMENITIES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.LandForCivicAmenities,
    [ESubCategory.COMMERCIAL_ZONE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.CommercialZone,
    [ESubCategory.INDUSTRIAL_ZONE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.IndustrialZone,
    [ESubCategory.MIXED_ZONE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.MixedZone,
    [ESubCategory.GARDEN]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Garden,
    [ESubCategory.ORCHARD]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Orchard,
    [ESubCategory.VINEYARD_AND_HOP_GARDEN]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.VineyardAndHopGarden,
    [ESubCategory.MEADOW_AND_PASTURE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.MeadowAndPasture,
    [ESubCategory.ARABLE_LAND]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.ArableLand,
    [ESubCategory.FOREST_LAND]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.ForestLand,
    [ESubCategory.POND_AND_LAKE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.PondAndLake,
    [ESubCategory.LAND_FOR_ADVERTISING]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.LandForAdvertising,
    [ESubCategory.OTHER_TYPE_OF_LAND]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OtherTypeOfLand,
    [ESubCategory.OFFICES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Offices,
    [ESubCategory.BUSINESS_SPACES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.BusinessSpaces,
    [ESubCategory.RESTAURANT_SPACES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.RestaurantSpaces,
    [ESubCategory.STORAGE_AREAS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.StorageAreas,
    [ESubCategory.SPACE_FOR_PRODUCTION]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SpaceForProduction,
    [ESubCategory.REPAIR_AREA]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.RepairArea,
    [ESubCategory.SPORTS_FACILITIES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SportsFacilities,
    [ESubCategory.SPACE_FOR_ADVERTISEMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SpaceForAdvertisement,
    [ESubCategory.OTHER_TYPE_OF_SPACE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OtherTypeOfSpace,
    [ESubCategory.APARTMENT_HOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.ApartmentHouse,
    [ESubCategory.RENTAL_HOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.RentalHouse,
    [ESubCategory.OFFICE_BUILDING]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OfficeBuilding,
    [ESubCategory.COMMERCIAL_OBJECT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.CommercialObject,
    [ESubCategory.RESTAURANT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Restaurant,
    [ESubCategory.POLYFUNCTIONAL_BUILDING]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.PolyfunctionalBuilding,
    [ESubCategory.WAREHOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Warehouse,
    [ESubCategory.MANUFACTURING_FACILITY]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.ManufacturingFacility,
    [ESubCategory.REPAIR_FACILITY]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.RepairFacility,
    [ESubCategory.OBJECT_FOR_BREEDING_ANIMALS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.ObjectForBreedingAnimals,
    [ESubCategory.HOTEL_AND_PENSION]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.HotelAndPension,
    [ESubCategory.SPA]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.Spa,
    [ESubCategory.HISTORICAL_OBJECT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.HistoricalObject,
    [ESubCategory.COMMERCIAL_FACILITY]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.CommercialFacility,
    [ESubCategory.SPORT_OBJECT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SportObject,
    [ESubCategory.SMALL_POWER_STATION]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SmallPowerStation,
    [ESubCategory.GAS_STATION]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.GasStation,
    [ESubCategory.MOBILE_CELLS_AND_STANDS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.MobileCellsAndStands,
    [ESubCategory.OTHER_TYPE_OF_OBJECT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OtherTypeOfObject,
    [ESubCategory.DETACHED_GARAGE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.DetachedGarage,
    [ESubCategory.CONSOLIDATED_GROUND]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.ConsolidatedGround,
    [ESubCategory.AGRICULTURAL_OBJECT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.AgriculturalObject,
    [ESubCategory.OTHER_LAND_TO_BUILDING]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OtherLandToBuilding,
    [ESubCategory.OTHER_AGRICULTURAL_LAND]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.OtherAgriculturalLand,
    [ESubCategory.SECONDARY_STUDIO_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryStudioApartment,
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryThreeRoomApartment,
    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryLandForFamilyHouses,
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryRecreationalLand,
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryAgriculturalAndForestLand,
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryCommercialPlots,
    [ESubCategory.SECONDARY_GARDEN]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryGarden,
    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryGarageAndParking,
    [ESubCategory.SECONDARY_A_RESTAURANT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryARestaurant,
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryOrchardsVineyardsHopFields,
    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryOfficesAndAdministration,
    [ESubCategory.SECONDARY_PRODUCTION]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryProduction,
    [ESubCategory.SECONDARY_WAREHOUSES]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryWarehouses,
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryHotelGuesthouse,
    [ESubCategory.SECONDARY_THE_SHOP]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryTheShop,
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]:
        AdvertisementsCountFilterParametersDtoCategoriesEnum.SecondaryAnotherSpaceAndObject,
};
