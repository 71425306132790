import {FC, ReactNode} from 'react';
import {Box} from '@mui/material';

interface IWrapper {
    children: ReactNode | ReactNode[];
}

export const Wrapper: FC<IWrapper> = ({children}) => {
    return <Box
        top={0}
        left={0}
        zIndex={2}
        right={0}
        bottom={0}
        padding={{xs: 2, md: 3}}
        display={'flex'}
        position={'absolute'}
        flexDirection={'column'}
        justifyContent={'space-between'}
    >
        {children}
    </Box>;
};
