import { AdvertisementsCountFilterParametersDtoTransactionEnum } from 'modules/api/generated';
import { ETransaction } from 'modules/api/clients/advertisement/common/enums/parameters/transaction/ETransaction';

export const TransactionEnumRecord: Record<
    ETransaction,
    AdvertisementsCountFilterParametersDtoTransactionEnum
> = {
    [ETransaction.PURCHASE]:
        AdvertisementsCountFilterParametersDtoTransactionEnum.Purchase,
    [ETransaction.HIREOUT]:
        AdvertisementsCountFilterParametersDtoTransactionEnum.Hireout,
    [ETransaction.RENT]:
        AdvertisementsCountFilterParametersDtoTransactionEnum.Rent,
    [ETransaction.EXCHANGE]:
        AdvertisementsCountFilterParametersDtoTransactionEnum.Exchange,
    [ETransaction.AUCTION]:
        AdvertisementsCountFilterParametersDtoTransactionEnum.Auction,
    [ETransaction.SALE]:
        AdvertisementsCountFilterParametersDtoTransactionEnum.Sale,
};
