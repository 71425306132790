import { AdvertisementSearchAgentSaveSearchAgentDtoFrequencyEnum } from 'modules/api/generated';
import { ESearchAgentFrequencyEnum } from 'components/searchAgent/enums/ESearchAgentFrequencyEnum';

export const FrequencyEnumRecord: Record<
    ESearchAgentFrequencyEnum,
    AdvertisementSearchAgentSaveSearchAgentDtoFrequencyEnum
> = {
    [ESearchAgentFrequencyEnum.IMMEDIATELY]:
        AdvertisementSearchAgentSaveSearchAgentDtoFrequencyEnum.Immediately,
    [ESearchAgentFrequencyEnum.EVERY_DAY]:
        AdvertisementSearchAgentSaveSearchAgentDtoFrequencyEnum.EveryDay,
    [ESearchAgentFrequencyEnum.EVERY_WEEK]:
        AdvertisementSearchAgentSaveSearchAgentDtoFrequencyEnum.EveryWeek,
    [ESearchAgentFrequencyEnum.DISABLED]:
        AdvertisementSearchAgentSaveSearchAgentDtoFrequencyEnum.Disabled,
};
