import {FC} from 'react';
import {DevProject} from 'modules/theme/components/assets/badges/DevProject';
import {ProfiClient} from 'modules/theme/components/assets/badges/ProfiClient';
import { Stack } from '@mui/material';

interface ILabels {
    isDevProject?: boolean;
    isProfi?: boolean;
}

export const Labels: FC<ILabels> = ({isDevProject, isProfi}) => {
    return <Stack direction={'row'} gap={1}>
        {isProfi && 
            <ProfiClient width={48} />
        }
        {isDevProject && 
            <DevProject width={48}/>
        }
    </Stack>;
};
