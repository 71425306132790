import * as Yup from 'yup';
import { ObjectShape, OptionalObjectSchema } from 'yup/lib/object';
import { StringSchema } from 'yup';
import { ISearchAgentValidationSchemaOptions } from 'components/searchAgent/interfaces/form/validation/ISearchAgentValidationSchemaOptions';
import { ISearchAgentData } from 'components/searchAgent/interfaces/form/data/ISearchAgentData';

const schemaOptions: ISearchAgentValidationSchemaOptions = {
    required: 'Povinné',
    agreeToTermsRequired: 'Súhlas je povinný',
    emailNotValid: 'Opravte prosím: Váš e-mail'
};

export const getSaveSearchValidationSchema = (): OptionalObjectSchema<
    ObjectShape,
    Record<string, StringSchema>
> => {
    return Yup.object().shape<Record<keyof ISearchAgentData, Yup.AnySchema>>({
        email: Yup.string().email(schemaOptions.emailNotValid).required(schemaOptions.required),
        searchName: Yup.string().required(schemaOptions.required),
        agreeToTerms: Yup.bool().isTrue(schemaOptions.agreeToTermsRequired),
        frequency: Yup.string().required(schemaOptions.required)
    });
};