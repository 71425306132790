import { AxiosResponse } from 'axios';
import { searchAgentApi } from 'modules/api/client';
import { AdvertisementSearchAgentSaveSearchAgentDto } from 'modules/api/generated';

export class SearchAgent {
    public async createSearchAgent(
        filter: AdvertisementSearchAgentSaveSearchAgentDto
    ): Promise<void> {
        let response: AxiosResponse<void>;

        try {
            response = await searchAgentApi.saveSearchAgent(filter);
        } catch (error) {
            throw new Error(
                `Failed to post searchAgentApi. Error: ${error.message}`
            );
        }

        if (response.status !== 201) {
            throw new Error('Post searchAgentApi failed');
        }
    }
}
